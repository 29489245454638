<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['comedones'])">
      <single-answer-question
        title="label.bumps"
        :value="comedones"
        :options="$options.bumpsOptions"
        dysfunction="skin-texture"
        @input="onFieldChange('comedones', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="comedoLocationsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['comedoLocations'])"
      >
        <face-zone-multi-select
          id="comedo-locations"
          title="label.bumpsLocations"
          hint="label.selectAllRegionsThatApply"
          :gender="gender"
          :value="comedoLocations"
          :options="$options.camedoLocationsOptions"
          field-name="texture"
          @input="onFieldChange('comedoLocations', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import FaceZoneMultiSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneMultiSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { AMOUNT_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { CAMEDO_LOCATIONS_OPTIONS } from '@/modules/questionnaire/constants/steps/skinTexture';

export default {
  name: 'BumpsTemplate',
  components: {
    FadeInTransitionWrap,
    FaceZoneMultiSelect,
    QuestionWithErrorWrap,
    SingleAnswerQuestion
  },
  bumpsOptions: AMOUNT_OPTIONS,
  camedoLocationsOptions: CAMEDO_LOCATIONS_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    comedones: {
      type: String,
      required: true
    },
    gender: {
      type: String,
      required: true
    },
    comedoLocations: {
      type: Array,
      required: true
    },
    comedoLocationsVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
